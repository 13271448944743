<template>
  <button
    class="ml-10"
    @click="logOut"
  >
    Log out
  </button>
</template>

<script>

  export default {
    name: 'Logout',

    methods: {
      logOut () {
        this.$firebaseAuth.signOut()
      },
    },
  }
</script>

<style scoped>

</style>
